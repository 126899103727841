import { createApp } from "vue";
import { createWebHistory, createRouter } from "vue-router";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faHome,
  faSpinner,
  faUpload,
  faImage,
  faText,
  faSave,
  faArrowToLeft,
  faArrowToRight,
  faBorderCenterV,
  faBringFront,
  faLock,
  faUnlockAlt,
  faClone,
  faTrashAlt,
  faIntersection,
  faUnion,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import App from "./App.vue";
import HomePage from "./pages/Home.vue";
import store from "./store";
import googlefonts from "./plugins/googlefonts";
import "./index.scss";

library.add(
  faHome,
  faSpinner,
  faUpload,
  faImage,
  faText,
  faSave,
  faArrowToLeft,
  faArrowToRight,
  faBorderCenterV,
  faBringFront,
  faLock,
  faUnlockAlt,
  faClone,
  faTrashAlt,
  faIntersection,
  faUnion
);

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      component: () => import("./pages/Home.vue"),
    },
  ],
});

createApp(App)
  .use(googlefonts)
  .use(store)
  .use(router)
  .component("fa", FontAwesomeIcon)
  .mount("#app");
