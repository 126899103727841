import { computed, ref, reactive } from "vue";
import { fabric } from "fabric";
import { nanoid } from "nanoid";
import * as fflate from "fflate";
import * as dayjs from "dayjs";
const download = require("downloadjs");

let canvas = null;
let canvasInitialized = false;
const state = reactive({
  selectedObjectAttributes: {
    type: null,
    left: 0,
    top: 0,
    width: 0,
    height: 0,
    fontFamily: null,
    color: "#000000",
  },
  templateDimensionsRect: null,
  templateMasks: null,
  templateGuides: null,
  templatePadding: 40,
  canvasWrapperRef: null,
  exportCounter: 0,
});
const layers = ref([]);
const selectedObjectName = ref(null);

export default function useCanvas() {
  const selectedObject = computed(function () {
    if (selectedObjectName.value) {
      return canvas.getItemByName(selectedObjectName.value);
    } else {
      return null;
    }
  });

  const selectedObjectLeft = computed({
    get() {
      return state.selectedObjectAttributes.left;
    },
    set(newValue) {
      setSelectedObjectLeft(parseInt(newValue));
    },
  });

  const selectedObjectTop = computed({
    get() {
      return state.selectedObjectAttributes.top;
    },
    set(value) {
      setSelectedObjectTop(parseInt(value));
    },
  });

  const selectedObjectScaleX = computed({
    get() {
      return state.selectedObjectAttributes.width;
    },
    set(value) {
      setSelectedObjectScaleX(parseInt(value));
    },
  });

  const selectedObjectScaleY = computed({
    get() {
      return state.selectedObjectAttributes.height;
    },
    set(value) {
      setSelectedObjectScaleY(parseInt(value));
    },
  });

  const selectedObjectFont = computed({
    get() {
      return state.selectedObjectAttributes.fontFamily;
    },
    set(value) {
      setSelectedObjectFont(value);
    },
  });

  function refreshLayers() {
    const tempLayers = canvas.getObjects();
    const filteredLayers = tempLayers.filter(
      (layer) => layer.name != "templateDimensionsRect"
    );
    let mappedLayers = filteredLayers.map(function (layer) {
      return {
        name: layer.name,
        type: layer.type,
      };
    });
    layers.value = mappedLayers.reverse();
  }

  function updateselectedObject(sourceObj) {
    state.selectedObjectAttributes.type = sourceObj.type;
    state.selectedObjectAttributes.left = Math.round(sourceObj.left);
    state.selectedObjectAttributes.top = Math.round(sourceObj.top);
    state.selectedObjectAttributes.width = Math.round(
      sourceObj.width * sourceObj.scaleX
    );
    state.selectedObjectAttributes.height = Math.round(
      sourceObj.height * sourceObj.scaleY
    );
    if (sourceObj.type === "i-text") {
      state.selectedObjectAttributes.fontFamily = sourceObj.fontFamily;
      state.selectedObjectAttributes.color = sourceObj.fill;
    }
  }

  function extendObjectExport(obj) {
    obj.toObject = (function (toObject) {
      return function () {
        return fabric.util.object.extend(toObject.call(this), {
          name: this.name,
          locked: this.locked,
        });
      };
    })(obj.toObject);
  }

  function zoomToTemplate() {
    canvas.setZoom(1);
    canvas.absolutePan({
      x: -state.templatePadding,
      y: -state.templatePadding,
    });

    if (
      state.templateDimensionsRect.width >= state.templateDimensionsRect.height
    ) {
      canvas.setZoom(
        state.canvasWrapperRef.clientWidth /
          (state.templateDimensionsRect.width + state.templatePadding * 2)
      );
    } else {
      canvas.setZoom(
        state.canvasWrapperRef.clientHeight /
          (state.templateDimensionsRect.height + state.templatePadding * 2)
      );
    }
  }

  function initCanvas(canvasRef, canvasWrapperRef) {
    if (canvasRef == undefined || canvasWrapperRef == undefined) {
      throw Error(
        "You must pass a wrapper for the canvas and the canvas wrapper div"
      );
    }

    if (canvasInitialized == true) {
      throw Error("Canvas was already initialized");
    }

    console.log("Init Canvas");

    fabric.Object.prototype.transparentCorners = false;

    fabric.Canvas.prototype.getItemByName = function (name) {
      var object = null,
        objects = this.getObjects();

      for (var i = 0, len = this.size(); i < len; i++) {
        if (objects[i].name && objects[i].name === name) {
          object = objects[i];
          break;
        }
      }

      return object;
    };

    canvas = new fabric.Canvas(canvasRef.value, {
      backgroundColor: "rgb(25,25,25)",
      preserveObjectStacking: true,
    });

    state.canvasWrapperRef = canvasWrapperRef;

    canvas.setDimensions({
      width: state.canvasWrapperRef.clientWidth,
      height: state.canvasWrapperRef.clientHeight,
    });

    canvas.on("selection:created", function (event) {
      if (event.selected.length == 1 && event.selected[0].name) {
        selectedObjectName.value = event.selected[0].name;
        updateselectedObject(event.selected[0]);
        refreshLayers();
        console.log(`Selected ${event.selected[0].name}`);
      }
    });

    canvas.on("selection:updated", function (event) {
      if (event.selected.length == 1 && event.selected[0].name) {
        selectedObjectName.value = event.selected[0].name;
        updateselectedObject(event.selected[0]);
        refreshLayers();
        console.log(`Selected ${event.selected[0].name}`);
      }
    });

    canvas.on("selection:cleared", function () {
      console.log("Selection cleared");
      selectedObjectName.value = null;
    });

    canvas.on("object:modified", function (event) {
      console.log(`Modified ${event.target.name}`);
      updateselectedObject(event.target);
    });

    canvas.on("object:added", function (event) {
      console.log(`Added ${event.target.name}`);
      refreshLayers();
    });

    canvas.on("object:removed", function (event) {
      console.log(`Removed ${event.target.name}`);
      refreshLayers();
    });

    canvasInitialized = true;

    canvas.renderAll();
  }

  function loadJSONTemplate(json) {
    if (!canvasInitialized) {
      throw Error("Canvas must be initialized before loading a template");
    }

    canvas.loadFromJSON(json, function () {
      // Set up masks
      state.templateDimensionsRect = canvas.getItemByName(
        "templateDimensionsRect"
      );
      extendObjectExport(state.templateDimensionsRect);
      state.templateDimensionsRect.set("selectable", false);
      state.templateDimensionsRect.set("hoverCursor", "default");
      state.templateMasks = state.templateDimensionsRect.clipPath;

      // Set up guides
      state.templateGuides = canvas.getItemByName("templateGuides");
      if (state.templateGuides) {
        extendObjectExport(state.templateGuides);
        state.templateGuides.set("selectable", false);
        state.templateGuides.set("hoverCursor", "default");
      }

      // export name for all custom objects
      for (let [index, val] of canvas.getObjects().entries()) {
        if (val.type === "image" || val.type === "i-text") {
          extendObjectExport(canvas.getObjects()[index]);
          canvas.getObjects()[index].setControlsVisibility({
            ml: false,
            mb: false,
            mr: false,
            mt: false,
          });
        }

        // if (val.type === "i-text") {
        //   // Bring Text to Front (TEMP)
        //   canvas.getObjects()[index].bringToFront();
        //   console.log("Bring text layers to front");
        // }

        if (canvas.getObjects()[index].locked == true) {
          canvas.getObjects()[index].set("lockMovementX", true);
          canvas.getObjects()[index].set("lockMovementY", true);
          canvas.getObjects()[index].set("lockScalingX", true);
          canvas.getObjects()[index].set("lockScalingY", true);
          canvas.getObjects()[index].set("lockRotation", true);
        }
      }

      refreshLayers();
      zoomToTemplate();
    });
  }

  function resizeCanvas() {
    if (canvasInitialized) {
      const outerCanvasContainer = document.getElementById(
        "fabric-canvas-wrapper"
      );

      const containerWidth = outerCanvasContainer.clientWidth;
      const containerHeight = outerCanvasContainer.clientHeight;
      // const scale = containerWidth / this.canvas.getWidth();
      // const zoom = this.canvas.getZoom() * scale;

      canvas.setDimensions({
        width: containerWidth,
        height: containerHeight,
      });
      //this.canvas.setViewportTransform([zoom, 0, 0, zoom, 0, 0]);
      console.log(`New Canvas width: ${containerWidth}px`);
      console.log(`New Canvas height: ${containerHeight}px`);

      zoomToTemplate();
    }
  }

  function addGuidesLandscape() {
    let line_1 = new fabric.Rect({
      fill: "cyan",
      top: 0,
      left: 30,
      width: 1,
      height: 1248,
    });
    let line_2 = new fabric.Rect({
      fill: "cyan",
      top: 0,
      left: 1817,
      width: 1,
      height: 1248,
    });
    let line_3 = new fabric.Rect({
      fill: "cyan",
      top: 30,
      left: 0,
      width: 1847,
      height: 1,
    });
    let line_4 = new fabric.Rect({
      fill: "cyan",
      top: 1218,
      left: 0,
      width: 1847,
      height: 1,
    });
    let guides = new fabric.Group([line_1, line_2, line_3, line_4], {
      name: "templateGuides",
      hasControls: false,
      absolutePositioned: true,
    });
    extendObjectExport(guides);
    guides.set("selectable", false);
    guides.set("hoverCursor", "default");
    canvas.add(guides);
  }

  function addGuidesStripe() {
    let line_1 = new fabric.Rect({
      fill: "cyan",
      top: 0,
      left: 45,
      width: 1,
      height: 1847,
    });
    let line_2 = new fabric.Rect({
      fill: "cyan",
      top: 0,
      left: 1203,
      width: 1,
      height: 1847,
    });
    let line_3 = new fabric.Rect({
      fill: "cyan",
      top: 45,
      left: 0,
      width: 1248,
      height: 1,
    });
    let line_4 = new fabric.Rect({
      fill: "cyan",
      top: 1772,
      left: 0,
      width: 1248,
      height: 1,
    });
    let guides = new fabric.Group([line_1, line_2, line_3, line_4], {
      name: "templateGuides",
      hasControls: false,
      absolutePositioned: true,
    });
    extendObjectExport(guides);
    guides.set("selectable", false);
    guides.set("hoverCursor", "default");
    canvas.add(guides);
  }

  function addTextLayer() {
    var text = new fabric.IText("Dein Text", {
      // fontFamily: this.defaultFont,
      fontFamily: "Comforter", // TODO: Fix
      left: 20,
      top: 20,
    });
    text.set("name", nanoid());
    text.setControlsVisibility({
      ml: false,
      mb: false,
      mr: false,
      mt: false,
    });
    extendObjectExport(text);
    canvas.add(text);
  }

  function handleImageSelect(e) {
    var file = e.target.files[0];
    var reader = new FileReader();
    reader.onload = function (f) {
      var data = f.target.result;
      fabric.Image.fromURL(data, function (img) {
        img.setControlsVisibility({
          ml: false,
          mb: false,
          mr: false,
          mt: false,
        });
        img.set("name", nanoid());
        extendObjectExport(img);
        canvas.add(img);
        canvas.setActiveObject(img);
        canvas.renderAll();
      });
    };
    reader.readAsDataURL(file);
  }

  function readFileAsync(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = reject;

      reader.readAsArrayBuffer(file);
    });
  }

  async function getTemplateAsZip() {
    let guides = canvas.getItemByName("templateGuides");
    if (guides) {
      guides.set("visible", false);
    }

    canvas.set("backgroundColor", null);
    canvas.renderAll.bind(canvas)();
    canvas.setZoom(1);
    canvas.absolutePan({
      x: 0,
      y: 0,
    });
    var dataURL = canvas.toDataURL({
      format: "png",
      left: 0,
      top: 0,
      width: state.templateDimensionsRect.width,
      height: state.templateDimensionsRect.height,
    });
    const blob = await (await fetch(dataURL)).blob();

    let contentBuffer = await readFileAsync(blob);

    if (guides) {
      guides.set("visible", true);
    }

    const zipped = fflate.zipSync(
      {
        "vorlage.json": fflate.strToU8(JSON.stringify(canvas)),
        "grafik.png": [new Uint8Array(contentBuffer), { level: 0 }],
      },
      {
        // These options are the defaults for all files, but file-specific
        // options take precedence.
        //level: 1,
        // Obfuscate mtime by default
        //mtime: 0
      }
    );

    zoomToTemplate();
    canvas.set("backgroundColor", "rgb(25,25,25)");
    canvas.renderAll();

    return zipped;
  }

  async function downloadTemplateZip() {
    let zip = await getTemplateAsZip();
    const timestamp = dayjs().format("YYYYMMDD-HH-mm");
    state.exportCounter++;
    download(
      zip,
      `schickbox_vorlage_${timestamp}_${state.exportCounter}.zip`,
      "application/zip"
    );
  }

  function setSelectedObjectLeft(left) {
    let element = canvas.getItemByName(selectedObjectName.value);
    element.set("left", left);
    state.selectedObjectAttributes.left = left;
    canvas.renderAll();
  }

  function setSelectedObjectTop(top) {
    let element = canvas.getItemByName(selectedObjectName.value);
    element.set("top", top);
    state.selectedObjectAttributes.top = top;
    canvas.renderAll();
  }

  function setSelectedObjectScaleX(width) {
    let element = canvas.getItemByName(selectedObjectName.value);
    const scale = width / element.width;
    element.set({
      scaleX: scale,
      scaleY: scale,
    });
    state.selectedObjectAttributes.width = width;
    state.selectedObjectAttributes.height = Math.round(scale * element.height);
    canvas.renderAll();
  }

  function setSelectedObjectScaleY(height) {
    let element = canvas.getItemByName(selectedObjectName.value);
    const scale = height / element.height;
    element.set({
      scaleX: scale,
      scaleY: scale,
    });
    state.selectedObjectAttributes.height = height;
    state.selectedObjectAttributes.width = Math.round(scale * element.width);
    canvas.renderAll();
  }

  function setSelectedObjectFont(value) {
    let element = canvas.getItemByName(selectedObjectName.value);
    state.selectedObjectAttributes.fontFamily = value;
    element.set("fontFamily", value);
    canvas.renderAll();
  }

  function setSelectedObjectColor(value) {
    let element = canvas.getItemByName(selectedObjectName.value);
    state.selectedObjectAttributes.color = value.hex;
    element.set(
      "fill",
      `rgba(${value.rgba.r},${value.rgba.g},${value.rgba.b},${value.rgba.a})`
    );
    canvas.renderAll();
  }

  function lockObject() {
    let element = canvas.getItemByName(selectedObjectName.value);
    element.set("lockMovementX", true);
    element.set("lockMovementY", true);
    element.set("lockScalingX", true);
    element.set("lockScalingY", true);
    element.set("lockRotation", true);
    element.set("locked", true);
  }

  function unlockObject() {
    let element = canvas.getItemByName(selectedObjectName.value);
    element.set("lockMovementX", false);
    element.set("lockMovementY", false);
    element.set("lockScalingX", false);
    element.set("lockScalingY", false);
    element.set("lockRotation", false);
    element.set("locked", false);
  }

  function duplicateObject() {
    canvas.getActiveObject().clone(function (clone) {
      clone.set("left", canvas.getActiveObject().left + 20);
      clone.set("top", canvas.getActiveObject().top + 20);
      clone.set("name", nanoid());
      clone.setControlsVisibility({
        ml: false,
        mb: false,
        mr: false,
        mt: false,
      });
      extendObjectExport(clone);
      canvas.add(clone);
    });
  }

  function setMaskingOn() {
    let element = canvas.getItemByName(selectedObjectName.value);
    element.clipPath = state.templateMasks;
    canvas.renderAll();
  }

  function setMaskingOff() {
    let element = canvas.getItemByName(selectedObjectName.value);
    element.clipPath = null;
    canvas.renderAll();
  }

  function bringForward() {
    let element = canvas.getItemByName(selectedObjectName.value);
    // Bring to front instead of only one step forward
    // element.bringForward();
    element.bringToFront();
    refreshLayers();
  }

  function alignLeftH() {
    setSelectedObjectLeft(0);
  }

  function alignMiddleH() {
    let element = canvas.getItemByName(selectedObjectName.value);

    setSelectedObjectLeft(
      Math.round(
        state.templateDimensionsRect.width * 0.5 -
          element.width * element.scaleX * 0.5
      )
    );
  }

  function alignRightH() {
    let element = canvas.getItemByName(selectedObjectName.value);

    setSelectedObjectLeft(
      Math.round(
        state.templateDimensionsRect.width - element.width * element.scaleX
      )
    );
  }

  function removeObject() {
    let element = canvas.getItemByName(selectedObjectName.value);
    canvas.remove(element);
    // canvas.renderAll();
  }

  function selectObjectByName(name) {
    let element = canvas.getItemByName(name);
    canvas.setActiveObject(element);
    canvas.renderAll();
  }

  return {
    addGuidesLandscape,
    addGuidesStripe,
    initCanvas,
    loadJSONTemplate,
    addTextLayer,
    resizeCanvas,
    handleImageSelect,
    getTemplateAsZip,
    downloadTemplateZip,
    selectedObject,
    selectedObjectLeft,
    selectedObjectTop,
    selectedObjectScaleX,
    selectedObjectScaleY,
    selectedObjectFont,
    setSelectedObjectColor,
    selectedObjectName,
    lockObject,
    unlockObject,
    duplicateObject,
    setMaskingOn,
    setMaskingOff,
    bringForward,
    alignLeftH,
    alignMiddleH,
    alignRightH,
    removeObject,
    selectObjectByName,
    layers,
    selectedObjectAttributes: state.selectedObjectAttributes,
  };
}
